<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <cubus-logo />
        <h2 class="brand-text text-primary ml-1">
          <div style="color: darkred">
            Cubus
          </div>
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            <span v-if="lang === 'ru'">Поехали! 🚀</span>
            <span v-else>Adventure starts here 🚀</span>
          </b-card-title>
          <b-card-text class="mb-2">
            <span v-if="lang === 'ru'">Управляйте своими данными легко!</span>
            <span v-else>Make your app management easy and fun!</span>
          </b-card-text>

          <!-- form -->
          <validation-observer ref="registerForm">
            <b-form class="auth-register-form mt-2">
              <!-- username -->
              <b-form-group
                  :label="lang === 'ru' ? ' Имя пользователя' : 'Username'"
                label-for="register-username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  rules="required"
                >
                  <b-form-input
                    id="register-username"
                    v-model="username"
                    name="register-username"
                    :state="errors.length > 0 ? false:null"
                    placeholder="johndoe"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group
                :label="lang === 'ru' ? ' E-мейл' : 'Email'"
                label-for="register-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="userEmail"
                    name="register-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label-for="register-password"
                :label="lang === 'ru' ? ' Пароль' : 'Password'"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="status"
                  name="checkbox-1"
                >
                  <span v-if="lang === 'ru'">
                    Я согласен с условиями <b-link>оферты</b-link>, <b-link>соглашения об использовании персональных данных</b-link>, использования <b-link>cookie</b-link> на сайте.
                  </span>
                  <span v-else>
                    I agree to <b-link>privacy policy & terms</b-link>
                  </span>
                </b-form-checkbox>
              </b-form-group>

              <b-button
                variant="primary"
                :disabled="!status"
                block
                type="submit"
                @click.prevent="validationForm"
              >
                <span v-if="lang === 'ru'">
                  Зарегистрироваться
                </span>
                <span v-else>
                  Sign up
                </span>
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span v-if="lang === 'ru'">Уже есть аккаунт? Тогда вам </span>
            <span v-else>Already have an account?</span>
            <b-link :to="{name:'auth-login-v2'}">
              <span v-if="lang === 'ru'">&nbsp;сюда</span>
              <span v-else>&nbsp;Sign in instead</span>
            </b-link>
          </p>

          <!-- divider -->
          <div v-if="false" class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div>

          <div v-if="false" class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div>

          <div class="demo-inline-spacing">
            <b-form-radio v-model="lang" name="ru" value="ru">
              <b-img v-if="false"
                  :src="ruLocale().img"
                  height="14px"
                  width="22px"
                  alt="ru"
              />  RU
            </b-form-radio>
            <b-form-radio v-model="lang" name="en" value="en">
              <b-img v-if="false"
                  :src="enLocale().img"
                  height="14px"
                  width="22px"
                  alt="en"
              />  EN
            </b-form-radio>
          </div>

          <div class="small text-muted" style="margin-top:2rem;">
            release: {{ release }}
          </div>
        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
  BFormRadio,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CubusLogo from '@/cubus/CubusLogo.vue'
import useJwt from '@/cubus/jwt/useJwt'
import useCubus from '@/cubus/services/useCubus'

export default {
  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    CubusLogo,
    BFormRadio,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      username: '',
      userEmail: '',
      password: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
      release: useCubus.release(),
      lang: 'ru',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  setup() {
    const locales = [
      {
        locale: 'en',
        img: require('@/assets/images/flags/en.png'),
        name: 'English',
      },
      {
        locale: 'ru',
        img: require('@/assets/images/flags/ru.png'),
        name: 'Russian',
      },
    ]
    /* eslint-disable global-require */
    return {
      locales,
    }
  },
  methods: {
    validationForm() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          useJwt.register({
            username: this.username,
            email: this.userEmail,
            password: this.password,
          })
            .then(response => {
              console.log('register response', response)
              if (response.data) {
                if (response.data.session) {
                  useJwt.setCubusToken(response.data.session.token)
                  this.$store.commit('cubus-store/SET_SESSION', response.data.session)
                  const sess = response.data.session
                  console.log('sess', sess)
                  // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                  // this.$router.replace(getHomeRouteForLoggedInUser(sess.user.role.name))
                  this.$router.replace('/')
                    .then(() => {
                      console.log('test 1')
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `Welcome ${sess.user.name || sess.user.username}`,
                          icon: 'CoffeeIcon',
                          variant: 'success',
                          text: `You have successfully logged in as ${sess.user.role.name}. Now you can start to explore!`,
                        },
                      })
                      console.log('test 2')
                    })
                } else if (response.data.error) {
                  useCubus.toastError(this, response.data.error)
                }
              }
            })
            .catch(error => {
              console.log('register error', error)
              useCubus.toastError(this, error.message)
            })
        }
      })
    },
    ruLocale() {
      return this.locales.find(l => l.locale === 'ru')
    },
    enLocale() {
      return this.locales.find(l => l.locale === 'en')
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '../../../@core/scss/vue/pages/page-auth.scss';
</style>
